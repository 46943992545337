<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>My Work</h1>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            label="Search My Tasks"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
            v-model="searchTerm"
          ></v-text-field>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  color="accent"
                  class="mr-2"
                  @click="$refs.workChunkForm.openForm()"
                  v-on="on"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Add Work</span>
            </v-tooltip>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            :headers="tableHeaders"
            :items="work_chunks"
            :items-per-page="-1"
            no-data-text="No work chunks found"
          >
            <template v-slot:item.name="{ item }">
              <router-link
                v-tooltip="{
                  content: item.description,
                  delay: { show: 400, hide: 0 },
                }"
                :to="{
                  name: 'module-workload-individual',
                  params: { chunkId: item.id },
                }"
                >{{ item.name }}</router-link
              >
            </template>
            <template v-slot:item.to_do="{ item }">
              {{ item.formatted_dates.to_do }}
            </template>

            <template v-slot:item.customer="{ item }">
              <router-link
                v-if="item.customer"
                :to="{
                  name: 'customer-profile-basic',
                  params: { customerId: item.customer.id },
                }"
                >{{ item.customer.name }}</router-link
              >
              <div v-if="item.is_internal == 1" style="color: #cccccc">
                VITAL HIKE
              </div>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    x-small
                    depressed
                    v-on="on"
                    class="mr-2"
                    color="accent"
                    @click="$refs.logTimeDialog.open(item)"
                  >
                    <v-icon x-small>mdi-alarm-plus</v-icon>
                  </v-btn>
                </template>
                <span>Log Time</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    x-small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    class="mr-2"
                    @click="$refs.workChunkForm.openForm(item)"
                  >
                    <v-icon x-small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
              <v-tooltip
                bottom
                v-if="
                  item.locked == 0 &&
                  item.status != 'Closed' &&
                  item.created_by == currentUserId
                "
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    x-small
                    depressed
                    color="red lighten-4 red--text"
                    class="mr-2"
                    v-on="on"
                    @click="$refs.closeTask.openClose(item)"
                  >
                    <v-icon x-small>mdi-package-variant-closed-remove</v-icon>
                  </v-btn>
                </template>
                <span>Close</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-if="item.created_by !== currentUserId"
                    x-small
                    depressed
                    v-on="on"
                    color="blue lighten-4 blue--text"
                    class="mr-2"
                    @click="openComplete(item)"
                  >
                    <v-icon x-small>mdi-check</v-icon>
                  </v-btn>
                </template>
                <span>Complete</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog
      v-model="completeTask.dialog"
      persistent
      max-width="400"
      :retain-focus="false"
    >
      <v-card>
        <v-card-title class="headline">Complete Task</v-card-title>
        <v-card-text
          >Are you sure you want to make this as complete?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetComplete"
            >No, cancel</v-btn
          >
          <v-btn
            color="success"
            depressed
            :loading="completeTask.loading"
            @click="saveComplete"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="archiveWorkloadDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Delete Workload</v-card-title>
        <v-card-text>
          Are you sure you want to delete
          {{ archiveWorkloadData ? archiveWorkloadData.name : "" }}?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="archiveWorkloadDialog = false"
            >No, cancel</v-btn
          >
          <v-btn
            color="success"
            depressed
            :loading="archiveWorkloadLoading"
            @click="archiveWorkload(archiveWorkloadData.id)"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="addWorkGroupForm.dialog" max-width="600px">
      <v-card>
        <v-form @submit.prevent="addWorkGroup" method="post">
          <v-card-title>
            <span class="headline">Add New Work Group</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Name *"
                  type="text"
                  v-model="addWorkGroupForm.name"
                  outlined
                  dense
                  :error="addWorkGroupForm.errors.hasOwnProperty('name')"
                  :error-messages="addWorkGroupForm.errors['name']"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="secondary"
              text
              @click="addWorkGroupForm.dialog = false"
              >Close</v-btn
            >
            <v-btn
              color="accent"
              text
              type="submit"
              :loading="addWorkGroupForm.loading"
              >Save</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <log-time-dialog ref="logTimeDialog" :editingWorkChunk="true" />
    <close-task ref="closeTask" :fromTasking="true" />
    <WorkChunkForm
      ref="workChunkForm"
      :groups="groups"
      :customers="customers"
      :teamMembers="teamMembers"
      :fromTasking="true"
    />
  </div>
</template>

<script>
import WorkChunkForm from "/src/modules/workload/views/chunks/components/WorkChunkForm.vue";
import CloseTask from "../components/CloseTask.vue";
import LogTimeDialog from "../components/LogTimeDialog.vue";

export default {
  components: {
    WorkChunkForm,
    LogTimeDialog,
    CloseTask,
  },

  data() {
    return {
      completeTask: {
        dialog: false,
        task: {},
        loading: false,
      },
      datePicker: false,
      statuses: ["Open", "Closed", "Deleted"],
      types: ["Project", "Ad-hoc", "Unknown", "Contract", "Diary"],
      searchTerm: "",
      breadcrumbs: [
        {
          text: "Workload",
          disabled: true,
        },
        {
          text: "My Tasks",
          disabled: true,
        },
      ],
      tableHeaders: [
        { text: "Name", value: "name" },
        { text: "Customer", value: "customer" },
        { text: "Owner", value: "team_member.full_name" },
        { text: "Hours Logged", value: "total_time_logged.text" },
        { text: "To Do Date", value: "to_do" },
        { text: "Priority", value: "priority" },
        { text: "Actions", value: "actions", align: "right" },
      ],

      addWorkGroupForm: {
        dialog: false,
        name: null,
        loading: false,
        errors: {},
      },
      addLogTimeForm: {
        dialog: false,
        date: new Date().toISOString().slice(0, 10),
        notes: null,
        title: null,
        minutes: null,
        chunk_id: null,
        loading: false,
        errors: {},
      },

      archiveWorkloadDialog: false,
      archiveWorkloadLoading: false,
      archiveWorkloadData: null,
    };
  },

  computed: {
    work_chunks() {
      let work_chunks = this.$store.getters["workload/assignedWorkChunks"];

      if (this.searchTerm !== "") {
        work_chunks = work_chunks.filter((c) => {
          const name = c.name.toLowerCase();
          const description = c.description ? c.description.toLowerCase() : "";
          const customer =
            c.customer !== null ? c.customer.name.toLowerCase() : "";
          const teamLeader =
            c.team_member !== null
              ? c.team_member.first_name.toLowerCase() +
                "" +
                c.team_member.last_name.toLowerCase()
              : "";

          return (
            name.includes(this.searchTerm.toLowerCase()) ||
            description.includes(this.searchTerm.toLowerCase()) ||
            customer.includes(this.searchTerm.toLowerCase()) ||
            teamLeader.includes(this.searchTerm.toLowerCase())
          );
        });
      }

      return work_chunks;
    },
    groups() {
      return this.$store.state.workload["groups"];
    },
    customers() {
      return this.$store.state.customers["customers"];
    },
    teamMembers() {
      return this.$store.state.team["teamMembers"];
    },
    currentUserId() {
      return this.$store.getters["users/currentUserId"];
    },
  },

  methods: {
    openComplete(task) {
      this.completeTask.task = task;
      this.completeTask.dialog = true;
    },

    resetComplete() {
      this.completeTask.dialog = false;
      this.completeTask.task = {};
      this.completeTask.loading = false;
    },

    saveComplete() {
      this.completeTask.loading = true;

      this.$store
        .dispatch("tasking/recurringStore/completeWorkChunk", {
          appId: this.$route.params.id,
          chunkId: this.completeTask.task.id,
        })
        .then(() => {
          this.resetComplete();
        })
        .catch(() => {
          this.completeTask.loading = false;
        });
    },

    archiveWorkload: function (id) {
      const appId = this.$route.params.id;
      this.archiveWorkloadLoading = true;

      this.$store
        .dispatch("workload/archiveWorkload", {
          appId,
          id,
        })
        .then(() => {
          this.archiveWorkloadData = null;
          this.archiveWorkloadLoading = false;
          this.archiveWorkloadDialog = false;
        });
    },

    addLogTime: function () {
      const appId = this.$route.params.id;
      const date = this.addLogTimeForm.date;
      const notes = this.addLogTimeForm.notes;
      const title = this.addLogTimeForm.title;
      const minutes = this.addLogTimeForm.minutes;
      const chunk_id = this.addLogTimeForm.chunk_id;

      this.addLogTimeForm.loading = true;
      this.addLogTimeForm.errors = {};

      this.$store
        .dispatch("workload/logTime", {
          appId,
          date,
          notes,
          title,
          minutes,
          chunk_id,
        })
        .then(() => {
          this.addLogTimeForm = {
            dialog: false,
            date: null,
            notes: null,
            title: null,
            minutes: null,
            chunk_id: null,
            loading: false,
            errors: {},
          };
        })
        .catch((err) => {
          this.addLogTimeForm.errors = err.response.data.errors;
          this.addLogTimeForm.loading = false;
        });
    },

    addWorkGroup: function () {
      const appId = this.$route.params.id;
      const name = this.addWorkGroupForm.name;

      this.addWorkGroupForm.loading = true;
      this.addWorkGroupForm.errors = {};

      this.$store
        .dispatch("workload/addGroup", {
          appId,
          name,
        })
        .then(() => {
          this.addWorkGroupForm = {
            dialog: false,
            name: null,
            loading: false,
            errors: {},
          };
        })
        .catch((err) => {
          this.addWorkGroupForm.errors = err.response.data.errors;
          this.addWorkGroupForm.loading = false;
        });
    },
  },
};
</script>
